import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import bofiscfullImg from "../../images/projectsfull/bofiscfull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bofisc</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="Bofisc"
              projectfulldescription="For accounting office Bofisc we developed a website to showcase their services, different branches and tools that are helpful for business owners. I designed the website and made custom icons to help explain their different services."
              projectclient="Bofisc"
              projectrole="Web Design & Front-end Development"
              projectdate="2017"
              projectfullimg={<div className="project--img--inner"><img data-src={bofiscfullImg} className="project--img lazyload" alt="Bofisc" /></div>}
              projectnextlink="/projects/salonstenboogaerde"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;